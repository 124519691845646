<template>
  <div
    class="tw-h-full tw-items-center tw-flex tw-flex-col tw-relative"
    :class="{
      'tw-bg-white':
        activeDesign === designs.DEFAULT ||
        activeDesign === designs.SIMPLE ||
        activeDesign === designs.BLUE_PALM ||
        activeDesign === designs.PURPLE_COW,
      'tw-bg-green': activeDesign === designs.COUNTRY,
      'tw-bg-orange': activeDesign === designs.SADDLE,
      'j-checkers': activeDesign === designs.BIRTHDAY,
      'j-green-palm': activeDesign === designs.GREEN_PALM,
      'j-purple-cow': activeDesign === designs.PURPLE_COW,
      'tw-bg-mimosa': activeDesign === designs.PINK_MIMOSA,
      'tw-bg-black': activeDesign === designs.RIP
    }"
    v-if="invitation"
  >
    <v-alert
      icon="mdi-account"
      dense
      dismissible
      type="warning"
      v-if="errorMessage.length > 0"
      class="tw-top-0 tw-rounded-none tw-absolute tw-w-full tw-z-10"
    >
      <p class="tw-mb-0">{{ errorMessage }}</p>
    </v-alert>
    <div class="tw-w-full tw-pt-8 tw-pl-8">
      <a href="https://www.letsjetty.com?utm_source=invite" target="_blank">
        <div
          class="tw-flex tw-flex-row tw-items-center tw-justify-start tw-mb-4"
        >
          <svg
            width="46"
            height="46"
            viewBox="0 0 46 46"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            class="tw-mr-3 tw-w-8"
          >
            <path
              d="M22.5373 0.960938H2.53744C1.48518 0.960938 0.632812 1.81331 0.632812 2.86557V12.7678H12.4374H20.1892H22.6567C28.366 12.7678 33.177 17.2687 33.2942 22.978C33.416 28.9101 28.582 33.7464 22.6475 33.6246C16.9382 33.5074 12.4374 28.6988 12.4374 22.9872V20.3887C12.4374 19.4972 11.716 18.7758 10.8246 18.7758H0.632812V22.8654C0.632812 35.1708 10.4868 45.3902 22.7923 45.4315C35.1322 45.4729 45.1447 35.4603 45.1034 23.1204C45.062 10.8149 34.8427 0.960938 22.5373 0.960938Z"
              :fill="fill"
            />
          </svg>
          <span
            class="tw-leading-8 tw-text-34 tw-font-medium tw-font-bebas-neue"
            :class="{
              'tw-text-white': whiteTitle(),
              'tw-text-dark-green': greenTitle(),
              'tw-text-blue-palm': palmTitle(),
              'tw-text-pink': pinkTitle(),
              'tw-text-black': blackTitle()
            }"
            >LET'S JETTY</span
          >
        </div>
      </a>
    </div>
    <template v-if="activeDesign === designs.BIRTHDAY">
      <div class="tw-w-full tw-py-6">
        <img
          src="@/assets/svgs/designs/birthday-invited.svg"
          alt="You're invited"
          class="tw-mx-auto tw-block tw-pb-14"
        />
        <div
          class="j-wavy j-670 tw-mx-12 tw--mt-10 tw-px-16 tw-pt-20 mobile-birthday"
        >
          <p
            class="tw-text-green tw-font-semibold tw-text-base tw-mt-6 tw-mb-0"
          >
            Trip
          </p>
          <p
            class="tw-text-22 tw-text-green tw-font-semibold tw-mx-4 tw-mb-0 tw-overflow-hidden line-clamp-2"
          >
            {{
              invitation.trip && invitation.trip.title
                ? invitation.trip.title
                : placeholderText
            }}
          </p>
          <p
            class="tw-text-green tw-font-semibold tw-text-base tw-mt-6 tw-mb-2"
          >
            Message
          </p>
          <p
            class="tw-text-green tw-text-base tw-font-semibold tw-mx-4 tw-mb-0 tw-overflow-hidden line-clamp-6"
          >
            {{ invitation.message }}
          </p>
          <p
            class="tw-text-green tw-font-semibold tw-text-base tw-mt-4 tw-mb-0"
          >
            Hosted by
          </p>
          <p class="tw-text-22 tw-text-green tw-font-semibold tw-mb-0">
            {{ invitation.fromName }}
          </p>

          <p
            class="tw-text-green tw-font-semibold tw-text-base tw-mt-6 tw-mb-0"
          >
            Date
          </p>
          <p class="tw-text-22 tw-text-green tw-font-semibold tw-mx-4 tw-mb-0">
            {{
              invitation.trip.startDate && invitation.trip.endDate
                ? getFormattedDateRange(
                    invitation.trip.startDate,
                    invitation.trip.endDate
                  )
                : placeholderText
            }}
          </p>
          <p
            class="tw-text-green tw-font-semibold tw-text-base tw-mt-6 tw-mb-0"
          >
            Destination
          </p>
          <p class="tw-text-22 tw-text-green tw-font-semibold tw-mx-4 tw-mb-0">
            {{
              invitation.trip.destinations.length
                ? invitation.trip.destinations[0].name
                : placeholderText
            }}
          </p>
        </div>
      </div>
      <div class="tw-pb-6">
        <p
          class="tw-font-figtree tw-font-semibold tw-text-white tw-text-22"
          v-if="invitation.rsvpDeadline"
        >
          RSVP Deadline:
          {{
            isExpired(invitation.rsvpDeadline)
              ? "Deadline has passed"
              : getFormattedDate(invitation.rsvpDeadline)
          }}
        </p>
        <div class="tw-flex tw-pb-4">
          <div class="tw-flex tw-flex-col tw-items-center tw-text-center">
            <button
              class="tw-pl-3 tw-pt-2 tw-pb-1 tw-pr-4 tw-text-34 tw-rounded-full tw-bg-white tw-mx-6 tw-cursor-pointer md:tw-text-54 md:tw-px-4 md:tw-py-0"
              :class="{
                'tw-bg-chartreuse': rsvpSelection?.value == 'accepted'
              }"
              @click="rsvpSelection = items[0]"
            >
              🤩
            </button>
            <p
              class="tw-text-xxs tw-text-white tw-mt-4 tw-mx-auto tw-max-w-80 lg:tw-max-w-100"
            >
              {{ items[0].text }}
            </p>
          </div>

          <div>
            <button
              class="tw-pl-3 tw-pt-2 tw-pb-1 tw-pr-4 tw-text-34 tw-rounded-full tw-bg-white tw-mx-6 tw-cursor-pointer md:tw-text-54 md:tw-px-4 md:tw-py-0"
              :class="{ 'tw-bg-chartreuse': rsvpSelection?.value == 'maybe' }"
              @click="rsvpSelection = items[1]"
            >
              🤔
            </button>
            <p
              class="tw-text-xxs tw-text-white tw-mt-4 tw-mx-auto tw-max-w-80 lg:tw-max-w-100"
            >
              {{ items[1].text }}
            </p>
          </div>

          <div>
            <button
              class="tw-pl-3 tw-pt-2 tw-pb-1 tw-pr-4 tw-text-34 tw-rounded-full tw-bg-white tw-mx-6 tw-cursor-pointer md:tw-text-54 md:tw-px-4 md:tw-py-0"
              :class="{
                'tw-bg-chartreuse': rsvpSelection?.value == 'declined'
              }"
              @click="rsvpSelection = items[2]"
            >
              😢
            </button>
            <p
              class="tw-text-xxs tw-text-white tw-mt-4 tw-mx-auto tw-max-w-80 lg:tw-max-w-100"
            >
              {{ items[2].text }}
            </p>
          </div>
        </div>
        <div class="tw-my-6 tw-px-4 md:tw-px-0">
          <v-btn
            type="button"
            class="tw-tracking-normal tw-bg-purple tw-text-white"
            block
            x-large
            rounded
            @click="submitRsvp"
            >Submit RSVP</v-btn
          >
        </div>
      </div>
    </template>
    <template v-if="activeDesign === designs.SADDLE">
      <div class="tw-w-full tw-bg-orange tw-py-6">
        <img
          src="@/assets/svgs/designs/saddle-invited.svg"
          alt="You're invited"
          class="tw-mx-auto tw-block tw-pb-14"
        />
        <img
          src="@/assets/svgs/designs/saddle-figure.svg"
          alt="You're invited"
          class="tw-mx-auto tw-block tw-pb-6"
        />
        <div
          class="tw-mx-4 custom-rounded tw-border-purple tw-border-t-11 tw-border-l-11 tw-border-r-11 tw-border-solid"
        >
          <div
            class="custom-rounded tw-border-light-purple tw-border-t-11 tw-border-l-11 tw-border-r-11 tw-border-solid"
          >
            <div
              class="custom-rounded tw-border-dark-orange tw-border-t-11 tw-border-l-11 tw-border-r-11 tw-border-solid"
            >
              <div class="custom-rounded tw-bg-white tw-p-4 tw-pb-12">
                <img
                  src="@/assets/svgs/designs/saddle-ball.svg"
                  alt="Invitation"
                  class="tw-block tw-mx-auto"
                />
                <p
                  class="tw-text-green tw-font-semibold tw-text-base tw-mt-6 tw-mb-0"
                >
                  Trip
                </p>
                <p
                  class="tw-text-22 tw-text-green tw-font-semibold tw-mx-4 tw-mb-0 tw-overflow-hidden line-clamp-2"
                >
                  {{
                    invitation.trip && invitation.trip.title
                      ? invitation.trip.title
                      : placeholderText
                  }}
                </p>
                <p
                  class="tw-text-green tw-font-semibold tw-text-base tw-mt-6 tw-mb-2"
                >
                  Message
                </p>
                <p
                  class="tw-text-green tw-text-base tw-font-semibold tw-mx-4 tw-mb-0 tw-overflow-hidden line-clamp-6"
                >
                  {{ invitation.message }}
                </p>
                <p
                  class="tw-text-green tw-font-semibold tw-text-base tw-mt-4 tw-mb-0"
                >
                  Hosted by
                </p>
                <p class="tw-text-22 tw-text-green tw-font-semibold tw-mb-0">
                  {{ invitation.fromName }}
                </p>
                <p
                  class="tw-text-green tw-font-semibold tw-text-base tw-mt-6 tw-mb-0"
                >
                  Date
                </p>
                <p
                  class="tw-text-22 tw-text-green tw-font-semibold tw-mx-4 tw-mb-0"
                >
                  {{
                    invitation.trip.startDate && invitation.trip.endDate
                      ? getFormattedDateRange(
                          invitation.trip.startDate,
                          invitation.trip.endDate
                        )
                      : placeholderText
                  }}
                </p>
                <p
                  class="tw-text-green tw-font-semibold tw-text-base tw-mt-6 tw-mb-0"
                >
                  Destination
                </p>
                <p
                  class="tw-text-22 tw-text-green tw-font-semibold tw-mx-4 tw-mb-0"
                >
                  {{
                    invitation.trip.destinations.length
                      ? invitation.trip.destinations[0].name
                      : placeholderText
                  }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="tw-pb-6">
        <p
          class="tw-font-figtree tw-font-semibold tw-text-white tw-text-22"
          v-if="invitation.rsvpDeadline"
        >
          RSVP Deadline:
          {{
            isExpired(invitation.rsvpDeadline)
              ? "Deadline has passed"
              : getFormattedDate(invitation.rsvpDeadline)
          }}
        </p>
        <div class="tw-flex tw-pb-4">
          <div class="tw-flex tw-flex-col tw-items-center tw-text-center">
            <button
              class="tw-pl-3 tw-pt-2 tw-pb-1 tw-pr-4 tw-text-34 tw-rounded-full tw-bg-white tw-mx-6 tw-cursor-pointer md:tw-text-54 md:tw-px-4 md:tw-py-0"
              :class="{
                'tw-bg-chartreuse': rsvpSelection?.value == 'accepted'
              }"
              @click="rsvpSelection = items[0]"
            >
              🤩
            </button>
            <p
              class="tw-text-xxs tw-text-white tw-mt-4 tw-mx-auto tw-max-w-80 lg:tw-max-w-100"
            >
              {{ items[0].text }}
            </p>
          </div>

          <div>
            <button
              class="tw-pl-3 tw-pt-2 tw-pb-1 tw-pr-4 tw-text-34 tw-rounded-full tw-bg-white tw-mx-6 tw-cursor-pointer md:tw-text-54 md:tw-px-4 md:tw-py-0"
              :class="{ 'tw-bg-chartreuse': rsvpSelection?.value == 'maybe' }"
              @click="rsvpSelection = items[1]"
            >
              🤔
            </button>
            <p
              class="tw-text-xxs tw-text-white tw-mt-4 tw-mx-auto tw-max-w-80 lg:tw-max-w-100"
            >
              {{ items[1].text }}
            </p>
          </div>

          <div>
            <button
              class="tw-pl-3 tw-pt-2 tw-pb-1 tw-pr-4 tw-text-34 tw-rounded-full tw-bg-white tw-mx-6 tw-cursor-pointer md:tw-text-54 md:tw-px-4 md:tw-py-0"
              :class="{
                'tw-bg-chartreuse': rsvpSelection?.value == 'declined'
              }"
              @click="rsvpSelection = items[2]"
            >
              😢
            </button>
            <p
              class="tw-text-xxs tw-text-white tw-mt-4 tw-mx-auto tw-max-w-80 lg:tw-max-w-100"
            >
              {{ items[2].text }}
            </p>
          </div>
        </div>
        <div class="tw-my-6 tw-px-4 md:tw-px-0">
          <v-btn
            type="button"
            class="tw-tracking-normal tw-bg-purple tw-text-white"
            block
            x-large
            rounded
            @click="submitRsvp"
            >Submit RSVP</v-btn
          >
        </div>
      </div>
    </template>
    <template v-if="activeDesign === designs.PURPLE_COW">
      <div class="tw-w-full tw-pt-6">
        <img
          src="@/assets/svgs/designs/cow-invited.svg"
          alt="You're invited"
          class="tw-mx-auto tw-block tw-pb-14"
        />
        <div class="tw-p-2 tw-pb-6 tw-mx-4 md:tw-mx-8 tw-bg-white">
          <div class="tw-flex tw-flex-col tw-justify-center tw-p-4">
            <p
              class="tw-text-green tw-font-semibold tw-text-base tw-mt-6 tw-mb-0"
            >
              Trip
            </p>
            <p
              class="tw-text-22 tw-text-green tw-font-semibold tw-mx-4 tw-mb-0 tw-overflow-hidden line-clamp-2"
            >
              {{
                invitation.trip && invitation.trip.title
                  ? invitation.trip.title
                  : placeholderText
              }}
            </p>
            <p
              class="tw-text-green tw-font-semibold tw-text-base tw-mt-6 tw-mb-2"
            >
              Message
            </p>
            <p
              class="tw-text-green tw-text-base tw-font-semibold tw-mx-4 tw-mb-0 tw-overflow-hidden line-clamp-6"
            >
              {{ invitation.message }}
            </p>
            <p
              class="tw-text-green tw-font-semibold tw-text-base tw-mt-4 tw-mb-0"
            >
              Hosted by
            </p>
            <p class="tw-text-22 tw-text-green tw-font-semibold tw-mb-0">
              {{ invitation.fromName }}
            </p>
            <p
              class="tw-text-green tw-font-semibold tw-text-base tw-mt-6 tw-mb-0"
            >
              Date
            </p>
            <p
              class="tw-text-22 tw-text-green tw-font-semibold tw-mx-4 tw-mb-0"
            >
              {{
                invitation.trip.startDate && invitation.trip.endDate
                  ? getFormattedDateRange(
                      invitation.trip.startDate,
                      invitation.trip.endDate
                    )
                  : placeholderText
              }}
            </p>
            <p
              class="tw-text-green tw-font-semibold tw-text-base tw-mt-6 tw-mb-0"
            >
              Destination
            </p>
            <p
              class="tw-text-22 tw-text-green tw-font-semibold tw-mx-4 tw-mb-0"
            >
              {{
                invitation.trip.destinations.length
                  ? invitation.trip.destinations[0].name
                  : placeholderText
              }}
            </p>
            <div class="tw-h-0.5 tw-mt-4 tw-mx-20 tw-bg-dark-green"></div>
            <p
              class="tw-font-figtree tw-font-semibold tw-mt-6 tw-text-22"
              v-if="invitation.rsvpDeadline"
            >
              RSVP Deadline:
              {{
                isExpired(invitation.rsvpDeadline)
                  ? "Deadline has passed"
                  : getFormattedDate(invitation.rsvpDeadline)
              }}
            </p>
          </div>
          <div class="tw-flex">
            <div class="tw-flex tw-flex-col tw-items-center tw-text-center">
              <button
                class="tw-pl-3 tw-pt-2 tw-pb-1 tw-pr-4 tw-text-34 tw-rounded-full tw-bg-white tw-shadow-custom tw-mx-6 tw-cursor-pointer md:tw-text-54 md:tw-px-4 md:tw-py-0"
                :class="{
                  'tw-bg-chartreuse': rsvpSelection?.value == 'accepted'
                }"
                @click="rsvpSelection = items[0]"
              >
                🤩
              </button>
              <p
                class="tw-text-xxs tw-mt-4 tw-mx-auto tw-max-w-80 lg:tw-max-w-100"
              >
                {{ items[0].text }}
              </p>
            </div>

            <div>
              <button
                class="tw-pl-3 tw-pt-2 tw-pb-1 tw-pr-4 tw-text-34 tw-rounded-full tw-bg-white tw-shadow-custom tw-mx-6 tw-cursor-pointer md:tw-text-54 md:tw-px-4 md:tw-py-0"
                :class="{ 'tw-bg-chartreuse': rsvpSelection?.value == 'maybe' }"
                @click="rsvpSelection = items[1]"
              >
                🤔
              </button>
              <p
                class="tw-text-xxs tw-mt-4 tw-mx-auto tw-max-w-80 lg:tw-max-w-100"
              >
                {{ items[1].text }}
              </p>
            </div>

            <div>
              <button
                class="tw-pl-3 tw-pt-2 tw-pb-1 tw-pr-4 tw-text-34 tw-rounded-full tw-bg-white tw-shadow-custom tw-mx-6 tw-cursor-pointer md:tw-text-54 md:tw-px-4 md:tw-py-0"
                :class="{
                  'tw-bg-chartreuse': rsvpSelection?.value == 'declined'
                }"
                @click="rsvpSelection = items[2]"
              >
                😢
              </button>
              <p
                class="tw-text-xxs tw-mt-4 tw-mx-auto tw-max-w-80 lg:tw-max-w-100"
              >
                {{ items[2].text }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="tw-pb-6 tw-w-80">
        <div class="tw-mt-8 tw-mb-6 tw-px-4 md:tw-px-0">
          <v-btn
            type="button"
            class="secondary tw-tracking-normal"
            block
            x-large
            rounded
            @click="submitRsvp"
            >Submit RSVP</v-btn
          >
        </div>
      </div>
    </template>
    <template v-if="activeDesign === designs.GREEN_PALM">
      <div class="tw-w-full tw-pt-6">
        <img
          src="@/assets/svgs/designs/green-palm-invited.svg"
          alt="You're invited"
          class="tw-mx-auto tw-block tw-pb-14"
        />
        <div class="tw-p-2 tw-pb-6 tw-mx-4 md:tw-mx-8 tw-bg-white">
          <div class="tw-flex tw-flex-col tw-justify-center tw-p-4">
            <p
              class="tw-text-green tw-font-semibold tw-text-base tw-mt-6 tw-mb-0"
            >
              Trip
            </p>
            <p
              class="tw-text-22 tw-text-green tw-font-semibold tw-mx-4 tw-mb-0 tw-overflow-hidden line-clamp-2"
            >
              {{
                invitation.trip && invitation.trip.title
                  ? invitation.trip.title
                  : placeholderText
              }}
            </p>
            <p
              class="tw-text-green tw-font-semibold tw-text-base tw-mt-6 tw-mb-2"
            >
              Message
            </p>
            <p
              class="tw-text-green tw-text-base tw-font-semibold tw-mx-4 tw-mb-0 tw-overflow-hidden line-clamp-6"
            >
              {{ invitation.message }}
            </p>
            <p
              class="tw-text-green tw-font-semibold tw-text-base tw-mt-4 tw-mb-0"
            >
              Hosted by
            </p>
            <p class="tw-text-22 tw-text-green tw-font-semibold tw-mb-0">
              {{ invitation.fromName }}
            </p>
            <p
              class="tw-text-green tw-font-semibold tw-text-base tw-mt-6 tw-mb-0"
            >
              Date
            </p>
            <p
              class="tw-text-22 tw-text-green tw-font-semibold tw-mx-4 tw-mb-0"
            >
              {{
                invitation.trip.startDate && invitation.trip.endDate
                  ? getFormattedDateRange(
                      invitation.trip.startDate,
                      invitation.trip.endDate
                    )
                  : placeholderText
              }}
            </p>
            <p
              class="tw-text-green tw-font-semibold tw-text-base tw-mt-6 tw-mb-0"
            >
              Destination
            </p>
            <p
              class="tw-text-22 tw-text-green tw-font-semibold tw-mx-4 tw-mb-0"
            >
              {{
                invitation.trip.destinations.length
                  ? invitation.trip.destinations[0].name
                  : placeholderText
              }}
            </p>
            <div class="tw-h-0.5 tw-mt-4 tw-mx-20 tw-bg-dark-green"></div>
            <p
              class="tw-font-figtree tw-font-semibold tw-mt-6 tw-text-22"
              v-if="invitation.rsvpDeadline"
            >
              RSVP Deadline:
              {{
                isExpired(invitation.rsvpDeadline)
                  ? "Deadline has passed"
                  : getFormattedDate(invitation.rsvpDeadline)
              }}
            </p>
          </div>
          <div class="tw-flex">
            <div class="tw-flex tw-flex-col tw-items-center tw-text-center">
              <button
                class="tw-pl-3 tw-pt-2 tw-pb-1 tw-pr-4 tw-text-34 tw-rounded-full tw-bg-white tw-shadow-custom tw-mx-6 tw-cursor-pointer md:tw-text-54 md:tw-px-4 md:tw-py-0"
                :class="{
                  'tw-bg-chartreuse': rsvpSelection?.value == 'accepted'
                }"
                @click="rsvpSelection = items[0]"
              >
                🤩
              </button>
              <p
                class="tw-text-xxs tw-mt-4 tw-mx-auto tw-max-w-80 lg:tw-max-w-100"
              >
                {{ items[0].text }}
              </p>
            </div>

            <div>
              <button
                class="tw-pl-3 tw-pt-2 tw-pb-1 tw-pr-4 tw-text-34 tw-rounded-full tw-bg-white tw-shadow-custom tw-mx-6 tw-cursor-pointer md:tw-text-54 md:tw-px-4 md:tw-py-0"
                :class="{ 'tw-bg-chartreuse': rsvpSelection?.value == 'maybe' }"
                @click="rsvpSelection = items[1]"
              >
                🤔
              </button>
              <p
                class="tw-text-xxs tw-mt-4 tw-mx-auto tw-max-w-80 lg:tw-max-w-100"
              >
                {{ items[1].text }}
              </p>
            </div>

            <div>
              <button
                class="tw-pl-3 tw-pt-2 tw-pb-1 tw-pr-4 tw-text-34 tw-rounded-full tw-bg-white tw-shadow-custom tw-mx-6 tw-cursor-pointer md:tw-text-54 md:tw-px-4 md:tw-py-0"
                :class="{
                  'tw-bg-chartreuse': rsvpSelection?.value == 'declined'
                }"
                @click="rsvpSelection = items[2]"
              >
                😢
              </button>
              <p
                class="tw-text-xxs tw-mt-4 tw-mx-auto tw-max-w-80 lg:tw-max-w-100"
              >
                {{ items[2].text }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="tw-pb-6 tw-w-80">
        <div class="tw-mt-8 tw-mb-6 tw-px-4 md:tw-px-0">
          <v-btn
            type="button"
            class="secondary tw-tracking-normal"
            block
            x-large
            rounded
            @click="submitRsvp"
            >Submit RSVP</v-btn
          >
        </div>
      </div>
    </template>
    <template v-if="activeDesign === designs.SIMPLE">
      <div class="j-simple-invitation tw-w-full tw-pt-6">
        <img
          src="@/assets/svgs/designs/simple-invited.svg"
          alt="You're invited"
          class="tw-mx-auto tw-block tw-pb-14"
        />
        <div class="tw-p-2 tw-pb-12 tw-mx-8 tw-bg-white">
          <div class="tw-flex tw-flex-col tw-justify-center tw-p-4">
            <p
              class="tw-text-green tw-font-semibold tw-text-base tw-mt-6 tw-mb-0"
            >
              Trip
            </p>
            <p
              class="tw-text-22 tw-text-green tw-font-semibold tw-mx-4 tw-mb-0 tw-overflow-hidden line-clamp-2"
            >
              {{
                invitation.trip && invitation.trip.title
                  ? invitation.trip.title
                  : placeholderText
              }}
            </p>
            <p
              class="tw-text-green tw-font-semibold tw-text-base tw-mt-6 tw-mb-2"
            >
              Message
            </p>
            <p
              class="tw-text-green tw-text-base tw-font-semibold tw-mx-4 tw-mb-0 tw-overflow-hidden line-clamp-6"
            >
              {{ invitation.message }}
            </p>
            <p
              class="tw-text-green tw-font-semibold tw-text-base tw-mt-4 tw-mb-0"
            >
              Hosted by
            </p>
            <p class="tw-text-22 tw-text-green tw-font-semibold tw-mb-0">
              {{ invitation.fromName }}
            </p>
            <p
              class="tw-text-green tw-font-semibold tw-text-base tw-mt-6 tw-mb-0"
            >
              Date
            </p>
            <p
              class="tw-text-22 tw-text-green tw-font-semibold tw-mx-4 tw-mb-0"
            >
              {{
                invitation.trip.startDate && invitation.trip.endDate
                  ? getFormattedDateRange(
                      invitation.trip.startDate,
                      invitation.trip.endDate
                    )
                  : placeholderText
              }}
            </p>
            <p
              class="tw-text-green tw-font-semibold tw-text-base tw-mt-6 tw-mb-0"
            >
              Destination
            </p>
            <p
              class="tw-text-22 tw-text-green tw-font-semibold tw-mx-4 tw-mb-0"
            >
              {{
                invitation.trip.destinations.length
                  ? invitation.trip.destinations[0].name
                  : placeholderText
              }}
            </p>
            <div class="tw-h-0.5 tw-mt-4 tw-mx-20 tw-bg-dark-green"></div>
            <p
              class="tw-font-figtree tw-font-semibold tw-mt-6 tw-text-22"
              v-if="invitation.rsvpDeadline"
            >
              RSVP Deadline:
              {{
                isExpired(invitation.rsvpDeadline)
                  ? "Deadline has passed"
                  : getFormattedDate(invitation.rsvpDeadline)
              }}
            </p>
          </div>
        </div>
      </div>
      <div class="tw-pb-6 tw--mt-12">
        <div class="tw-flex tw-pb-4">
          <div class="tw-flex tw-flex-col tw-items-center tw-text-center">
            <button
              class="tw-pl-3 tw-pt-2 tw-pb-1 tw-pr-4 tw-text-34 tw-rounded-full tw-bg-white tw-shadow-custom tw-mx-6 tw-cursor-pointer md:tw-text-54 md:tw-px-4 md:tw-py-0"
              :class="{
                'tw-bg-chartreuse': rsvpSelection?.value == 'accepted'
              }"
              @click="rsvpSelection = items[0]"
            >
              🤩
            </button>
            <p
              class="tw-text-xxs tw-mt-4 tw-mx-auto tw-max-w-80 lg:tw-max-w-100"
            >
              {{ items[0].text }}
            </p>
          </div>

          <div>
            <button
              class="tw-pl-3 tw-pt-2 tw-pb-1 tw-pr-4 tw-text-34 tw-rounded-full tw-bg-white tw-shadow-custom tw-mx-6 tw-cursor-pointer md:tw-text-54 md:tw-px-4 md:tw-py-0"
              :class="{ 'tw-bg-chartreuse': rsvpSelection?.value == 'maybe' }"
              @click="rsvpSelection = items[1]"
            >
              🤔
            </button>
            <p
              class="tw-text-xxs tw-mt-4 tw-mx-auto tw-max-w-80 lg:tw-max-w-100"
            >
              {{ items[1].text }}
            </p>
          </div>

          <div>
            <button
              class="tw-pl-3 tw-pt-2 tw-pb-1 tw-pr-4 tw-text-34 tw-rounded-full tw-bg-white tw-shadow-custom tw-mx-6 tw-cursor-pointer md:tw-text-54 md:tw-px-4 md:tw-py-0"
              :class="{
                'tw-bg-chartreuse': rsvpSelection?.value == 'declined'
              }"
              @click="rsvpSelection = items[2]"
            >
              😢
            </button>
            <p
              class="tw-text-xxs tw-mt-4 tw-mx-auto tw-max-w-80 lg:tw-max-w-100"
            >
              {{ items[2].text }}
            </p>
          </div>
        </div>
        <div class="tw-my-6 tw-px-4 md:tw-px-0">
          <v-btn
            type="button"
            class="secondary tw-tracking-normal"
            block
            x-large
            rounded
            @click="submitRsvp"
            >Submit RSVP</v-btn
          >
        </div>
      </div>
    </template>
    <template v-if="activeDesign === designs.COUNTRY">
      <div class="tw-w-full tw-bg-green tw-py-6">
        <img
          src="@/assets/svgs/designs/country-invited.svg"
          alt="You're invited"
          class="tw-mx-auto tw-block tw-pb-14"
        />
        <div class="tw-p-2 tw-bg-white tw-mx-4">
          <div
            class="tw-flex tw-flex-col tw-justify-center tw-p-4 tw-border-green tw-border-3 tw-border-solid"
          >
            <img
              src="@/assets/svgs/designs/country-glass.svg"
              alt="Country glass"
            />
            <p
              class="tw-text-green tw-font-semibold tw-text-base tw-mt-6 tw-mb-0"
            >
              Trip
            </p>
            <p
              class="tw-text-22 tw-text-green tw-font-semibold tw-mx-4 tw-mb-0 tw-overflow-hidden line-clamp-2"
            >
              {{
                invitation.trip && invitation.trip.title
                  ? invitation.trip.title
                  : placeholderText
              }}
            </p>
            <p
              class="tw-text-green tw-font-semibold tw-text-base tw-mt-6 tw-mb-2"
            >
              Message
            </p>
            <p
              class="tw-text-green tw-text-base tw-font-semibold tw-mx-4 tw-mb-0 tw-overflow-hidden line-clamp-6"
            >
              {{ invitation.message }}
            </p>
            <p
              class="tw-text-green tw-font-semibold tw-text-base tw-mt-4 tw-mb-0"
            >
              Hosted by
            </p>
            <p class="tw-text-22 tw-text-green tw-font-semibold tw-mb-0">
              {{ invitation.fromName }}
            </p>
            <p
              class="tw-text-green tw-font-semibold tw-text-base tw-mt-6 tw-mb-0"
            >
              Date
            </p>
            <p
              class="tw-text-22 tw-text-green tw-font-semibold tw-mx-4 tw-mb-0"
            >
              {{
                invitation.trip.startDate && invitation.trip.endDate
                  ? getFormattedDateRange(
                      invitation.trip.startDate,
                      invitation.trip.endDate
                    )
                  : placeholderText
              }}
            </p>
            <p
              class="tw-text-green tw-font-semibold tw-text-base tw-mt-6 tw-mb-0"
            >
              Destination
            </p>
            <p
              class="tw-text-22 tw-text-green tw-font-semibold tw-mx-4 tw-mb-0"
            >
              {{
                invitation.trip.destinations.length
                  ? invitation.trip.destinations[0].name
                  : placeholderText
              }}
            </p>
          </div>
        </div>
      </div>
      <div class="tw-pb-6">
        <p
          class="tw-font-figtree tw-font-semibold tw-text-white tw-text-22"
          v-if="invitation.rsvpDeadline"
        >
          RSVP Deadline:
          {{
            isExpired(invitation.rsvpDeadline)
              ? "Deadline has passed"
              : getFormattedDate(invitation.rsvpDeadline)
          }}
        </p>
        <div class="tw-flex tw-pb-4">
          <div class="tw-flex tw-flex-col tw-items-center tw-text-center">
            <button
              class="tw-pl-3 tw-pt-2 tw-pb-1 tw-pr-4 tw-text-34 tw-rounded-full tw-bg-white tw-mx-6 tw-cursor-pointer md:tw-text-54 md:tw-px-4 md:tw-py-0"
              :class="{
                'tw-bg-chartreuse': rsvpSelection?.value == 'accepted'
              }"
              @click="rsvpSelection = items[0]"
            >
              🤩
            </button>
            <p
              class="tw-text-xxs tw-text-white tw-mt-4 tw-mx-auto tw-max-w-80 lg:tw-max-w-100"
            >
              {{ items[0].text }}
            </p>
          </div>

          <div>
            <button
              class="tw-pl-3 tw-pt-2 tw-pb-1 tw-pr-4 tw-text-34 tw-rounded-full tw-bg-white tw-mx-6 tw-cursor-pointer md:tw-text-54 md:tw-px-4 md:tw-py-0"
              :class="{ 'tw-bg-chartreuse': rsvpSelection?.value == 'maybe' }"
              @click="rsvpSelection = items[1]"
            >
              🤔
            </button>
            <p
              class="tw-text-xxs tw-text-white tw-mt-4 tw-mx-auto tw-max-w-80 lg:tw-max-w-100"
            >
              {{ items[1].text }}
            </p>
          </div>

          <div>
            <button
              class="tw-pl-3 tw-pt-2 tw-pb-1 tw-pr-4 tw-text-34 tw-rounded-full tw-bg-white tw-mx-6 tw-cursor-pointer md:tw-text-54 md:tw-px-4 md:tw-py-0"
              :class="{
                'tw-bg-chartreuse': rsvpSelection?.value == 'declined'
              }"
              @click="rsvpSelection = items[2]"
            >
              😢
            </button>
            <p
              class="tw-text-xxs tw-text-white tw-mt-4 tw-mx-auto tw-max-w-80 lg:tw-max-w-100"
            >
              {{ items[2].text }}
            </p>
          </div>
        </div>
        <div class="tw-my-6 tw-px-4 md:tw-px-0">
          <v-btn
            type="button"
            class="tw-tracking-normal tw-bg-white"
            block
            x-large
            rounded
            @click="submitRsvp"
            >Submit RSVP</v-btn
          >
        </div>
      </div>
    </template>
    <template v-if="activeDesign === designs.RIP">
      <div class="tw-w-full tw-py-6">
        <img
          src="@/assets/svgs/designs/rip-invited.svg"
          alt="You're invited"
          class="tw-mx-auto tw-block tw-pb-14"
        />
        <div class="tw-p-2 tw-bg-white tw-mx-4">
          <div
            class="tw-flex tw-flex-col tw-justify-center tw-p-4 tw-border-black tw-border-8 tw-border-solid"
          >
            <img
              src="@/assets/svgs/designs/rip-graphic.svg"
              alt="Rip graphic"
              class="tw-my-6 tw-mx-auto"
            />
            <p
              class="tw-text-black tw-font-semibold tw-text-base tw-mt-6 tw-mb-0"
            >
              Trip
            </p>
            <p
              class="tw-text-22 tw-text-black tw-font-semibold tw-mx-4 tw-mb-0 tw-overflow-hidden line-clamp-2"
            >
              {{
                invitation.trip && invitation.trip.title
                  ? invitation.trip.title
                  : placeholderText
              }}
            </p>
            <p
              class="tw-text-black tw-font-semibold tw-text-base tw-mt-6 tw-mb-2"
            >
              Message
            </p>
            <p
              class="tw-text-black tw-text-base tw-font-semibold tw-mx-4 tw-mb-0 tw-overflow-hidden line-clamp-6"
            >
              {{ invitation.message }}
            </p>
            <p
              class="tw-text-black tw-font-semibold tw-text-base tw-mt-4 tw-mb-0"
            >
              Hosted by
            </p>
            <p class="tw-text-22 tw-text-black tw-font-semibold tw-mb-0">
              {{ invitation.fromName }}
            </p>
            <p
              class="tw-text-black tw-font-semibold tw-text-base tw-mt-6 tw-mb-0"
            >
              Date
            </p>
            <p
              class="tw-text-22 tw-text-black tw-font-semibold tw-mx-4 tw-mb-0"
            >
              {{
                invitation.trip.startDate && invitation.trip.endDate
                  ? getFormattedDateRange(
                      invitation.trip.startDate,
                      invitation.trip.endDate
                    )
                  : placeholderText
              }}
            </p>
            <p
              class="tw-text-black tw-font-semibold tw-text-base tw-mt-6 tw-mb-0"
            >
              Destination
            </p>
            <p
              class="tw-text-22 tw-text-black tw-font-semibold tw-mx-4 tw-mb-0"
            >
              {{
                invitation.trip.destinations.length
                  ? invitation.trip.destinations[0].name
                  : placeholderText
              }}
            </p>
          </div>
        </div>
      </div>
      <div class="tw-pb-6">
        <p
          class="tw-font-figtree tw-font-semibold tw-text-white tw-text-22"
          v-if="invitation.rsvpDeadline"
        >
          RSVP Deadline:
          {{
            isExpired(invitation.rsvpDeadline)
              ? "Deadline has passed"
              : getFormattedDate(invitation.rsvpDeadline)
          }}
        </p>
        <div class="tw-flex tw-pb-4">
          <div class="tw-flex tw-flex-col tw-items-center tw-text-center">
            <button
              class="tw-pl-3 tw-pt-2 tw-pb-1 tw-pr-4 tw-text-34 tw-rounded-full tw-bg-white tw-mx-6 tw-cursor-pointer md:tw-text-54 md:tw-px-4 md:tw-py-0"
              :class="{
                'tw-bg-chartreuse': rsvpSelection?.value == 'accepted'
              }"
              @click="rsvpSelection = items[0]"
            >
              🤩
            </button>
            <p
              class="tw-text-xxs tw-text-white tw-mt-4 tw-mx-auto tw-max-w-80 lg:tw-max-w-100"
            >
              {{ items[0].text }}
            </p>
          </div>

          <div>
            <button
              class="tw-pl-3 tw-pt-2 tw-pb-1 tw-pr-4 tw-text-34 tw-rounded-full tw-bg-white tw-mx-6 tw-cursor-pointer md:tw-text-54 md:tw-px-4 md:tw-py-0"
              :class="{ 'tw-bg-chartreuse': rsvpSelection?.value == 'maybe' }"
              @click="rsvpSelection = items[1]"
            >
              🤔
            </button>
            <p
              class="tw-text-xxs tw-text-white tw-mt-4 tw-mx-auto tw-max-w-80 lg:tw-max-w-100"
            >
              {{ items[1].text }}
            </p>
          </div>

          <div>
            <button
              class="tw-pl-3 tw-pt-2 tw-pb-1 tw-pr-4 tw-text-34 tw-rounded-full tw-bg-white tw-mx-6 tw-cursor-pointer md:tw-text-54 md:tw-px-4 md:tw-py-0"
              :class="{
                'tw-bg-chartreuse': rsvpSelection?.value == 'declined'
              }"
              @click="rsvpSelection = items[2]"
            >
              😢
            </button>
            <p
              class="tw-text-xxs tw-text-white tw-mt-4 tw-mx-auto tw-max-w-80 lg:tw-max-w-100"
            >
              {{ items[2].text }}
            </p>
          </div>
        </div>
        <div class="tw-my-6 tw-px-4 md:tw-px-0">
          <v-btn
            type="button"
            class="tw-tracking-normal tw-bg-white tw-text-black"
            block
            x-large
            rounded
            @click="submitRsvp"
            >Submit RSVP</v-btn
          >
        </div>
      </div>
    </template>
    <template v-if="activeDesign === designs.PINK_MIMOSA">
      <div class="tw-w-full tw-bg-mimosa tw-py-6">
        <img
          src="@/assets/svgs/designs/pink-mimosa-invited.svg"
          alt="You're invited"
          class="tw-mx-auto tw-block tw-pb-14"
        />
        <div
          class="tw-p-2 tw-bg-white tw-border-pink tw-border-5 tw-border-solid tw-mx-4"
        >
          <div
            class="tw-flex tw-flex-col tw-justify-center tw-p-4 tw-border-pink tw-border-3 tw-border-solid"
          >
            <img
              src="@/assets/svgs/designs/pink-mimosa-glass.svg"
              alt="Pink Mimosa glass"
              class="tw-w-24 tw-my-6 tw-mx-auto"
            />
            <p
              class="tw-text-mimosa-black tw-font-semibold tw-text-base tw-mt-6 tw-mb-0"
            >
              Trip
            </p>
            <p
              class="tw-text-22 tw-text-mimosa-black tw-font-semibold tw-mx-4 tw-mb-0 tw-overflow-hidden line-clamp-2"
            >
              {{
                invitation.trip && invitation.trip.title
                  ? invitation.trip.title
                  : placeholderText
              }}
            </p>
            <p
              class="tw-text-mimosa-black tw-font-semibold tw-text-base tw-mt-6 tw-mb-2"
            >
              Message
            </p>
            <p
              class="tw-text-mimosa-black tw-text-base tw-font-semibold tw-mx-4 tw-mb-0 tw-overflow-hidden line-clamp-6"
            >
              {{ invitation.message }}
            </p>
            <p
              class="tw-text-mimosa-black tw-font-semibold tw-text-base tw-mt-4 tw-mb-0"
            >
              Hosted by
            </p>
            <p class="tw-text-22 tw-text-mimosa-black tw-font-semibold tw-mb-0">
              {{ invitation.fromName }}
            </p>
            <p
              class="tw-text-mimosa-black tw-font-semibold tw-text-base tw-mt-6 tw-mb-0"
            >
              Date
            </p>
            <p
              class="tw-text-22 tw-text-mimosa-black tw-font-semibold tw-mx-4 tw-mb-0"
            >
              {{
                invitation.trip.startDate && invitation.trip.endDate
                  ? getFormattedDateRange(
                      invitation.trip.startDate,
                      invitation.trip.endDate
                    )
                  : placeholderText
              }}
            </p>
            <p
              class="tw-text-mimosa-black tw-font-semibold tw-text-base tw-mt-6 tw-mb-0"
            >
              Destination
            </p>
            <p
              class="tw-text-22 tw-text-mimosa-black tw-font-semibold tw-mx-4 tw-mb-0"
            >
              {{
                invitation.trip.destinations.length
                  ? invitation.trip.destinations[0].name
                  : placeholderText
              }}
            </p>
          </div>
        </div>
      </div>
      <div class="tw-pb-6">
        <p
          class="tw-font-figtree tw-font-semibold tw-text-mimosa-black tw-text-22"
          v-if="invitation.rsvpDeadline"
        >
          RSVP Deadline:
          {{
            isExpired(invitation.rsvpDeadline)
              ? "Deadline has passed"
              : getFormattedDate(invitation.rsvpDeadline)
          }}
        </p>
        <div class="tw-flex tw-pb-4">
          <div class="tw-flex tw-flex-col tw-items-center tw-text-center">
            <button
              class="tw-pl-3 tw-pt-2 tw-pb-1 tw-pr-4 tw-text-34 tw-rounded-full tw-bg-white tw-mx-6 tw-cursor-pointer md:tw-text-54 md:tw-px-4 md:tw-py-0"
              :class="{
                'tw-bg-chartreuse': rsvpSelection?.value == 'accepted'
              }"
              @click="rsvpSelection = items[0]"
            >
              🤩
            </button>
            <p
              class="tw-text-xxs tw-text-mimosa-black tw-mt-4 tw-mx-auto tw-max-w-80 lg:tw-max-w-100"
            >
              {{ items[0].text }}
            </p>
          </div>

          <div>
            <button
              class="tw-pl-3 tw-pt-2 tw-pb-1 tw-pr-4 tw-text-34 tw-rounded-full tw-bg-white tw-mx-6 tw-cursor-pointer md:tw-text-54 md:tw-px-4 md:tw-py-0"
              :class="{ 'tw-bg-chartreuse': rsvpSelection?.value == 'maybe' }"
              @click="rsvpSelection = items[1]"
            >
              🤔
            </button>
            <p
              class="tw-text-xxs tw-text-mimosa-black tw-mt-4 tw-mx-auto tw-max-w-80 lg:tw-max-w-100"
            >
              {{ items[1].text }}
            </p>
          </div>

          <div>
            <button
              class="tw-pl-3 tw-pt-2 tw-pb-1 tw-pr-4 tw-text-34 tw-rounded-full tw-bg-white tw-mx-6 tw-cursor-pointer md:tw-text-54 md:tw-px-4 md:tw-py-0"
              :class="{
                'tw-bg-chartreuse': rsvpSelection?.value == 'declined'
              }"
              @click="rsvpSelection = items[2]"
            >
              😢
            </button>
            <p
              class="tw-text-xxs tw-text-mimosa-black tw-mt-4 tw-mx-auto tw-max-w-80 lg:tw-max-w-100"
            >
              {{ items[2].text }}
            </p>
          </div>
        </div>
        <div class="tw-my-6 tw-px-4 md:tw-px-0">
          <v-btn
            type="button"
            class="tw-tracking-normal tw-bg-j-orange tw-text-white"
            block
            x-large
            rounded
            @click="submitRsvp"
            >Submit RSVP</v-btn
          >
        </div>
      </div>
    </template>
    <template v-if="activeDesign === designs.BLUE_PALM">
      <div class="tw-w-full tw-bg-white tw-py-6">
        <img
          src="@/assets/svgs/designs/blue-palm-invited.svg"
          alt="You're invited"
          class="tw-mx-auto tw-block tw-pb-14"
        />
        <div
          class="tw-p-2 tw-bg-white tw-border-blue-palm tw-border-5 tw-border-solid tw-mx-4"
        >
          <div
            class="tw-flex tw-flex-col tw-justify-center tw-p-4 tw-border-blue-palm tw-border-3 tw-border-solid"
          >
            <img
              src="@/assets/svgs/designs/blue-palms.svg"
              alt="Pink Mimosa glass"
              class="tw-w-24 tw-my-6 tw-mx-auto"
            />
            <p
              class="tw-text-mimosa-black tw-font-semibold tw-text-base tw-mt-6 tw-mb-0"
            >
              Trip
            </p>
            <p
              class="tw-text-22 tw-text-mimosa-black tw-font-semibold tw-mx-4 tw-mb-0 tw-overflow-hidden line-clamp-2"
            >
              {{
                invitation.trip && invitation.trip.title
                  ? invitation.trip.title
                  : placeholderText
              }}
            </p>
            <p
              class="tw-text-mimosa-black tw-font-semibold tw-text-base tw-mt-6 tw-mb-2"
            >
              Message
            </p>
            <p
              class="tw-text-mimosa-black tw-text-base tw-font-semibold tw-mx-4 tw-mb-0 tw-overflow-hidden line-clamp-6"
            >
              {{ invitation.message }}
            </p>
            <p
              class="tw-text-mimosa-black tw-font-semibold tw-text-base tw-mt-4 tw-mb-0"
            >
              Hosted by
            </p>
            <p class="tw-text-22 tw-text-mimosa-black tw-font-semibold tw-mb-0">
              {{ invitation.fromName }}
            </p>
            <p
              class="tw-text-mimosa-black tw-font-semibold tw-text-base tw-mt-6 tw-mb-0"
            >
              Date
            </p>
            <p
              class="tw-text-22 tw-text-mimosa-black tw-font-semibold tw-mx-4 tw-mb-0"
            >
              {{
                invitation.trip.startDate && invitation.trip.endDate
                  ? getFormattedDateRange(
                      invitation.trip.startDate,
                      invitation.trip.endDate
                    )
                  : placeholderText
              }}
            </p>
            <p
              class="tw-text-mimosa-black tw-font-semibold tw-text-base tw-mt-6 tw-mb-0"
            >
              Destination
            </p>
            <p
              class="tw-text-22 tw-text-mimosa-black tw-font-semibold tw-mx-4 tw-mb-0"
            >
              {{
                invitation.trip.destinations.length
                  ? invitation.trip.destinations[0].name
                  : placeholderText
              }}
            </p>
          </div>
        </div>
      </div>
      <div class="tw-pb-6">
        <p
          class="tw-font-figtree tw-font-semibold tw-text-mimosa-black tw-text-22"
          v-if="invitation.rsvpDeadline"
        >
          RSVP Deadline:
          {{
            isExpired(invitation.rsvpDeadline)
              ? "Deadline has passed"
              : getFormattedDate(invitation.rsvpDeadline)
          }}
        </p>
        <div class="tw-flex tw-pb-4">
          <div class="tw-flex tw-flex-col tw-items-center tw-text-center">
            <button
              class="tw-shadow-custom tw-pl-3 tw-pt-2 tw-pb-1 tw-pr-4 tw-text-34 tw-rounded-full tw-bg-white tw-mx-6 tw-cursor-pointer md:tw-text-54 md:tw-px-4 md:tw-py-0"
              :class="{
                'tw-bg-chartreuse': rsvpSelection?.value == 'accepted'
              }"
              @click="rsvpSelection = items[0]"
            >
              🤩
            </button>
            <p
              class="tw-text-xxs tw-text-mimosa-black tw-mt-4 tw-mx-auto tw-max-w-80 lg:tw-max-w-100"
            >
              {{ items[0].text }}
            </p>
          </div>

          <div>
            <button
              class="tw-shadow-custom tw-pl-3 tw-pt-2 tw-pb-1 tw-pr-4 tw-text-34 tw-rounded-full tw-bg-white tw-mx-6 tw-cursor-pointer md:tw-text-54 md:tw-px-4 md:tw-py-0"
              :class="{ 'tw-bg-chartreuse': rsvpSelection?.value == 'maybe' }"
              @click="rsvpSelection = items[1]"
            >
              🤔
            </button>
            <p
              class="tw-text-xxs tw-text-mimosa-black tw-mt-4 tw-mx-auto tw-max-w-80 lg:tw-max-w-100"
            >
              {{ items[1].text }}
            </p>
          </div>

          <div>
            <button
              class="tw-shadow-custom tw-pl-3 tw-pt-2 tw-pb-1 tw-pr-4 tw-text-34 tw-rounded-full tw-bg-white tw-mx-6 tw-cursor-pointer md:tw-text-54 md:tw-px-4 md:tw-py-0"
              :class="{
                'tw-bg-chartreuse': rsvpSelection?.value == 'declined'
              }"
              @click="rsvpSelection = items[2]"
            >
              😢
            </button>
            <p
              class="tw-text-xxs tw-text-mimosa-black tw-mt-4 tw-mx-auto tw-max-w-80 lg:tw-max-w-100"
            >
              {{ items[2].text }}
            </p>
          </div>
        </div>
        <div class="tw-my-6 tw-px-4 md:tw-px-0">
          <v-btn
            type="button"
            class="tw-tracking-normal tw-bg-blue-palm tw-text-white"
            block
            x-large
            rounded
            @click="submitRsvp"
            >Submit RSVP</v-btn
          >
        </div>
      </div>
    </template>
    <template v-if="activeDesign === designs.DEFAULT">
      <div class="tw-px-4 tw-pb-36 j-star-bg-small">
        <h1
          class="tw-font-perfectly-nineties tw-text-center tw-text-86 tw-text-dark-green tw-mb-9 tw-leading-none"
        >
          You're invited
        </h1>
        <p class="tw-text-base tw-text-dark-green tw-mb-0 tw-px-8">
          {{ invitation.message }}
        </p>
      </div>
      <div class="tw-text-left tw-relative tw-bg-chartreuse tw-p-10 tw-w-full">
        <div class="tw--mt-36 tw-relative">
          <div class="j-rsvp-top"></div>
          <div class="j-ticket-item tw-px-10 tw-py-9 tw-bg-white">
            <p class="tw-text-light-grey tw-font-semibold tw-text-base tw-mb-0">
              Hosted by
            </p>
            <p class="tw-text-20 tw-text-charcoal tw-font-semibold tw-mb-0">
              {{ invitation.fromName }}
            </p>
          </div>
          <div class="j-ticket-item tw-px-10 tw-pb-9 tw-bg-white">
            <p class="tw-text-light-grey tw-font-semibold tw-text-base tw-mb-0">
              Trip
            </p>
            <p class="tw-text-20 tw-text-charcoal tw-font-semibold tw-mb-0">
              {{
                invitation.trip && invitation.trip.title
                  ? invitation.trip.title
                  : placeholderText
              }}
            </p>
          </div>
          <div class="j-ticket-item tw-px-10 tw-pb-9 tw-bg-white">
            <p class="tw-text-light-grey tw-font-semibold tw-text-base tw-mb-0">
              Date
            </p>
            <p
              class="tw-text-20 tw-text-charcoal tw-font-semibold tw-mb-0"
              v-if="invitation.trip.startDate && invitation.trip.endDate"
            >
              {{
                getFormattedDateRange(
                  invitation.trip.startDate,
                  invitation.trip.endDate
                )
              }}
            </p>
            <p
              v-else
              class="tw-text-20 tw-text-charcoal tw-font-semibold tw-mb-0"
            >
              {{ placeholderText }}
            </p>
          </div>
          <div class="j-ticket-item tw-px-10 tw-pb-9 tw-bg-white">
            <p class="tw-text-light-grey tw-font-semibold tw-text-base tw-mb-0">
              Destination
            </p>
            <p
              class="tw-text-20 tw-text-charcoal tw-font-semibold tw-mb-0"
              v-if="invitation.trip.destinations.length"
            >
              {{ invitation.trip.destinations[0].name }}
            </p>
            <p
              v-else
              class="tw-text-20 tw-text-charcoal tw-font-semibold tw-mb-0"
            >
              {{ placeholderText }}
            </p>
          </div>

          <div class="j-ticket-item tw-px-10 tw-pb-9 tw-bg-white">
            <p
              class="tw-text-light-grey tw-font-semibold tw-text-base"
              v-if="invitation.rsvpDeadline"
            >
              RSVP Deadline:
              {{
                isExpired(invitation.rsvpDeadline)
                  ? "Deadline has passed"
                  : getFormattedDate(invitation.rsvpDeadline)
              }}
            </p>
            <v-select
              v-model="rsvpSelection"
              return-value
              :items="items"
              label="Interested?"
              solo
              item-color="secondary"
              color="secondary"
              class="centered-select"
            >
              <template #prepend-inner>
                <div>
                  <img
                    class="tw-w-6"
                    src="@/assets/svgs/rsvp-ticket.svg"
                    alt="Ticket Icon"
                  />
                </div>
              </template>
            </v-select>
          </div>
          <div class="j-rsvp-bottom"></div>
        </div>
        <v-btn
          x-large
          rounded
          block
          depressed
          class="j-btn tw-bg-dark-green tw-text-white tw-mt-10 tw-tracking-normal"
          type="button"
          @click="submitRsvp"
          >Submit RSVP</v-btn
        >
        <div class="tw-text-center tw-mt-2">
          <router-link
            class="tw-text-dark-green tw-block tw-mx-auto tw-border-b tw-border-solid tw-border-dark-green tw-font-semibold tw-inline-block"
            :to="{ name: 'TripViewRoute', params: { id: invitation.trip.id } }"
          >
            Back to Trip Hub
          </router-link>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
import { DateTime } from "luxon";
import { mapActions } from "vuex";
import { FormattedDateRange } from "@/mixins/FormattedDateRange.js";
import { DESIGNS } from "@/enums/designs.js";
export default {
  name: "Invitation",
  mixins: [FormattedDateRange],
  data() {
    return {
      designs: DESIGNS,
      activeDesign: DESIGNS.DEFAULT,
      errorMessage: "",
      uuid: null,
      rsvpSelection: null,
      placeholderText: "TBD",
      items: [
        {
          text: "Definitely interested!",
          value: "accepted"
        },
        {
          text: "Maybe, keep me updated",
          value: "maybe"
        },
        {
          text: "No, not this time",
          value: "declined"
        }
      ]
    };
  },
  computed: {
    sessionUser() {
      return this.$store.state.auth.user;
    },
    isInvitationGetPending() {
      return this.$store.state["trip-invitation"].isGetPending;
    },
    invitation() {
      return this.$store.state["trip-invitation"].keyedById[this.uuid];
    },
    fill() {
      if (
        this.activeDesign === DESIGNS.DEFAULT ||
        this.activeDesign === DESIGNS.SIMPLE ||
        this.activeDesign === DESIGNS.BIRTHDAY
      )
        return "#203848";
      if (
        this.activeDesign === DESIGNS.COUNTRY ||
        this.activeDesign === DESIGNS.SADDLE ||
        this.activeDesign === DESIGNS.RIP
      )
        return "#fff";
      if (this.activeDesign === DESIGNS.PINK_MIMOSA) return "#FF2DF7";
      if (this.activeDesign === DESIGNS.BLUE_PALM) return "#549BF8";
      if (
        this.activeDesign === DESIGNS.PURPLE_COW ||
        this.activeDesign === DESIGNS.GREEN_PALM
      )
        return "#000";
      return "#fff";
    }
  },
  methods: {
    ...mapActions({
      getInvitation: "trip-invitation/get",
      acceptTripInvite: "trip-invitation-accept/create"
    }),
    whiteTitle() {
      if (
        this.activeDesign === this.designs.COUNTRY ||
        this.activeDesign === this.designs.SADDLE ||
        this.activeDesign === this.designs.RIP
      )
        return true;
      return false;
    },
    greenTitle() {
      if (
        this.activeDesign === this.designs.DEFAULT ||
        this.activeDesign === this.designs.SIMPLE ||
        this.activeDesign === this.designs.BIRTHDAY
      )
        return true;
      return false;
    },
    pinkTitle() {
      return this.activeDesign === this.designs.PINK_MIMOSA;
    },
    blackTitle() {
      if (
        this.activeDesign === this.designs.GREEN_PALM ||
        this.activeDesign === this.designs.PURPLE_COW
      )
        return true;
      return false;
    },
    palmTitle() {
      return this.activeDesign === this.designs.BLUE_PALM;
    },
    isNewUser() {
      const now = DateTime.now();
      const start = this.$store.state.auth.user
        ? DateTime.fromISO(this.$store.state.auth.user.createdAt)
        : DateTime.now();
      const diff = start.diff(now, "days").toObject();
      const count = Math.abs(Math.round(diff.days));
      if (count <= 7 && this.$store.state.auth.user) return true;
      return false;
    },
    setErrorMessage(message) {
      this.errorMessage = message;
      window.scrollTo(0, 0);
    },
    isExpired(deadline) {
      const currentDate = DateTime.now().toISODate();
      const rsvpDeadline = deadline;
      return currentDate > rsvpDeadline;
    },
    async submitRsvp() {
      this.errorMessage = "";
      if (this.isExpired(this.invitation.rsvpDeadline)) {
        this.setErrorMessage(
          "The response deadline has passed. You can no longer RSVP to this trip."
        );
        return;
      }
      if (!this.rsvpSelection) {
        this.setErrorMessage("Please select a response");
        return;
      }

      if (!this.sessionUser) {
        localStorage.setItem(
          "invitationPending",
          JSON.stringify({
            tripId: this.invitation.trip.id,
            selection:
              this.activeDesign === this.designs.DEFAULT
                ? this.rsvpSelection
                : this.rsvpSelection.value,
            uuid: this.uuid,
            tripHasSurvey:
              this.invitation.trip.surveys &&
              this.invitation.trip.surveys.length > 0,
            invitationData: this.invitation
          })
        );
        this.setErrorMessage("You are logged out, redirecting you to login...");
        setTimeout(() => {
          this.$router.push("/");
        }, 2000);
        return;
      }

      try {
        const res = await this.acceptTripInvite({
          userId: this.sessionUser.id,
          tripInvitationUUID: this.uuid,
          status:
            this.activeDesign === this.designs.DEFAULT
              ? this.rsvpSelection
              : this.rsvpSelection.value
        });

        let hasDates = false;
        if (this.invitation.trip.startDate && this.invitation.trip.endDate)
          hasDates = true;

        window.dataLayer.push({
          event: "rsvp_trip_invite",
          uuid: this.$store.state.auth.user
            ? this.$store.state.auth.user.uuid
            : null,
          signup_date: this.$store.state.auth.user
            ? DateTime.fromISO(this.$store.state.auth.user.createdAt).toISO()
            : null,
          new_user: this.isNewUser(),
          verified_user: this.$store.state.auth.user
            ? this.$store.state.auth.user.isEmailVerified
            : false,
          sign_up_trip_id: this.$store.state.auth.user
            ? this.$store.state.auth.user.signupTripId
            : null,
          trip_id: this.invitation.tripId,
          trip_invite_id: this.invitation.id,
          owner_id: this.invitation.ownerId,
          has_dates: hasDates,
          has_destination:
            this.invitation &&
            this.invitation.trip &&
            this.invitation.trip.destinations &&
            this.invitation.trip.destinations.length > 0,
          response_deadline: this.invitation.rsvpDeadline,
          timestamp: DateTime.now().toISO(),
          rsvp_response: res.status
        });

        const tripHasSurvey =
          this.invitation.trip.surveys &&
          this.invitation.trip.surveys.length > 0;

        sessionStorage.setItem("fromInvitation", true);

        if (
          res.status === "accepted" ||
          res.status === "maybe" ||
          res.status === "pending"
        ) {
          const routeName = tripHasSurvey
            ? "SurveyRoute"
            : "RSVPConfirmationYes";
          this.$router.push({
            name: routeName,
            params: {
              id: this.invitation.trip.id,
              surveyId:
                this.invitation.trip.surveys[
                  this.invitation.trip.surveys.length - 1
                ]?.id
            }
          });
        } else if (res.status === "declined") {
          this.$router.push({
            name: "RSVPConfirmationNo",
            params: { id: this.invitation.trip.id }
          });
        }
      } catch (error) {
        error.message
          ? this.setErrorMessage(error.message)
          : this.setErrorMessage("Error submitting RSVP");
        console.error("Error submitting RSVP:", error);
      }
    }
  },
  async beforeMount() {
    this.uuid = this.$route.params.uuid;
    await this.getInvitation(this.uuid);

    this.activeDesign = this.invitation.template
      ? this.invitation.template
      : this.designs.DEFAULT;

    let hasDates = false;
    if (this.invitation.trip.startDate && this.invitation.trip.endDate)
      hasDates = true;

    window.dataLayer.push({
      event: "view_trip_invite",
      uuid: this.$store.state.auth.user
        ? this.$store.state.auth.user.uuid
        : null,
      signup_date: this.$store.state.auth.user
        ? DateTime.fromISO(this.$store.state.auth.user.createdAt).toISO()
        : null,
      new_user: this.isNewUser(),
      verified_user: this.$store.state.auth.user
        ? this.$store.state.auth.user.isEmailVerified
        : false,
      sign_up_trip_id: this.$store.state.auth.user
        ? this.$store.state.auth.user.signupTripId
        : null,
      logged_in: this.$store.state.auth.user ? true : false,
      trip_id: this.invitation.tripId,
      owner_id: this.invitation.ownerId,
      has_dates: hasDates,
      has_destination:
        this.invitation &&
        this.invitation.trip &&
        this.invitation.trip.destinations &&
        this.invitation.trip.destinations.length > 0,
      response_deadline: this.invitation.rsvpDeadline,
      survey_attached:
        this.invitation.trip.surveys && this.invitation.trip.surveys.length > 0
          ? true
          : false,
      timestamp: DateTime.now().toISO()
    });
  }
};
</script>
<style lang="scss">
.centered-select .v-input__control .v-input__slot .v-select__slot .v-label {
  text-align: center;
  width: 100%;
}

.centered-select
  .v-input__control
  .v-input__slot
  .v-select__slot
  .v-select__selection {
  text-align: center;
  width: 100%;
  margin-left: 0.75rem;
}

.line-clamp-2 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.line-clamp-6 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
}

.mobile-birthday {
  @media (max-width: 440px) {
    padding-left: 4rem !important;
    padding-right: 4rem !important;

    margin-left: 2rem !important;
    margin-right: 1rem !important;
  }

  @media (max-width: 391px) {
    padding-left: 3rem !important;
    padding-right: 3rem !important;

    margin-left: -0.5rem !important;
    margin-right: 0rem !important;
  }
}
</style>
